import React, { useEffect, useState } from "react";
import "./DotsAnimation.css";

const heartShape = [
  { x: 50, y: 30 }, { x: 47, y: 35 }, { x: 53, y: 35 },
  { x: 44, y: 40 }, { x: 56, y: 40 }, { x: 41, y: 45 },
  { x: 59, y: 45 }, { x: 38, y: 50 }, { x: 62, y: 50 },
  { x: 35, y: 55 }, { x: 65, y: 55 }, { x: 50, y: 70 },
];

const DotsAnimation = () => {
  const [dots, setDots] = useState([]);
  const [showLove, setShowLove] = useState(false);

  useEffect(() => {
    // Generate random dots with the same length as heartShape
    const generateDots = () => {
      const dotsArray = heartShape.map(() => ({
        x: Math.random() * 100, // Posisi acak awal
        y: Math.random() * 100,
      }));
      setDots(dotsArray);
    };

    generateDots();

    // Trigger love formation after 2 seconds
    const timeout = setTimeout(() => {
      setShowLove(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="dots-container">
      {dots.map((dot, index) => (
        <div
          key={index}
          className={`dot ${showLove ? "to-love" : ""}`}
          style={{
            left: `${showLove ? heartShape[index].x : dot.x}%`,
            top: `${showLove ? heartShape[index].y : dot.y}%`,
            animationDelay: `${Math.random()}s`, // Animasi acak untuk awal
          }}
        ></div>
      ))}
    </div>
  );
};

export default DotsAnimation;
