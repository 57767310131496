import React from "react";
import { AudioCall, ChevronLeft, FooterWa, Nabi1, Number, VideoCall } from "../assets";

const WhatsAppContainer = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "430px",
        height: "100vh",
        margin: "0 auto",
        backgroundColor: "#ece5dd",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #ccc",
        
      }}
    >
      <div
        style={{
          backgroundColor: "#ffff",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          padding: "10px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={ChevronLeft} alt="Back button" />
          <img src={Number} alt="Contact number" style={{ marginLeft: "5px" }} />
          <img
            src={Nabi1}
            alt="Contact avatar"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          />
          <div>
            <strong
              style={{ margin: 0, fontSize: "18px", color: "black" }}
            >
              Sayang🤍
            </strong>
            <p style={{ margin: 0, fontSize: "12px", color: "#d1d1d1" }}>
              Online
            </p>
          </div>
        </div>
        <div>
          <button
            style={{
              background: "transparent",
              border: "none",
              marginRight: "10px",
            }}
          >
            <img src={AudioCall} alt="Audio call button" />
          </button>
          <button style={{ background: "transparent", border: "none" }}>
            <img src={VideoCall} alt="Video call button" />
          </button>
        </div>
      </div>

      {/* Chat Content */}
      <div
        style={{
          flex: 1,
          padding: "10px",
          overflowY: "auto",
          backgroundColor: "#ece5dd",
        }}
      >
        {children}
      </div>

      {/* Footer */}
      <div>
        <img src={FooterWa} alt="Footer background" style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default WhatsAppContainer;
