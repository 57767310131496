import React from "react";
import "./box1.css"; // File CSS untuk styling

const Box1 = () => {
  return (
    <div className="box1-container">
      <div className="box1-content">
        <h2>Happy Birthday</h2>
        <p>once again happy birthday sayangku, this is our small library hehehe</p>
      </div>
    </div>
  );
};

export default Box1;
