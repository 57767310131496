/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import {
  Foto1, Foto2, Foto3, Foto4, Foto5, Foto6, Foto7, Foto8, Foto9, Foto10,
  Foto11, Foto12, Foto13, Foto14, Foto15, Foto16, Foto17, Foto18, Foto19, Foto20,
  Foto21, Foto22, Foto23, Foto24, Foto25, Foto26, Foto27, Foto28, Foto29, Foto30,
  Foto31, Foto32, Foto33, Foto34, Foto35, Foto36, Foto37, Foto38, Foto39, Foto40,
  Foto41, Foto42, Foto43, Foto44, Foto45, Foto46, Foto47, Foto48, Foto49, Foto50,
  Foto51, Foto52, Foto53, Foto54, Foto55, Foto56, Foto57, Foto58, Foto59
} from "../../../assets/index.js"; // Sesuaikan path ke file ekspor default Anda

import "./box3.css"; // Styling untuk Box3

const Box3 = () => {
  // Daftar foto yang akan ditampilkan
  const photos = [
    Foto1, Foto2, Foto3, Foto4, Foto5, Foto6, Foto7, Foto8, Foto9, Foto10,
    Foto11, Foto12, Foto13, Foto14, Foto15, Foto16, Foto17, Foto18, Foto19, Foto20,
    Foto21, Foto22, Foto23, Foto24, Foto25, Foto26, Foto27, Foto28, Foto29, Foto30,
    Foto31, Foto32, Foto33, Foto34, Foto35, Foto36, Foto37, Foto38, Foto39, Foto40,
    Foto41, Foto42, Foto43, Foto44, Foto45, Foto46, Foto47, Foto48, Foto49, Foto50,
    Foto51, Foto52, Foto53, Foto54, Foto55, Foto56, Foto57, Foto58, Foto59,
  ];

  const itemsPerPage = 3; // Jumlah foto yang ditampilkan per halaman
  const [currentPage, setCurrentPage] = useState(1);

  // Fungsi untuk menghitung foto yang ditampilkan berdasarkan halaman
  const indexOfLastPhoto = currentPage * itemsPerPage;
  const indexOfFirstPhoto = indexOfLastPhoto - itemsPerPage;
  const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

  // Fungsi untuk mengubah halaman
  const nextPage = () => {
    if (currentPage < Math.ceil(photos.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="box3-container">
      <div className="box3-content">
        <h2>Photo Gallery</h2>
        <div className="photo-grid">
          {currentPhotos.map((photo, index) => (
            <div className="photo-item" key={index}>
              <img src={photo} alt={`Photo ${index + 1}`} />
            </div>
          ))}
        </div>
        <div className="pagination">
          <button onClick={prevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage}</span>
          <button
            onClick={nextPage}
            disabled={currentPage === Math.ceil(photos.length / itemsPerPage)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Box3;
