/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoadingScreen.css";
import { Nabi8 } from "../assets";

const LoadingScreen = ({ onNext }) => {
  const navigate = useNavigate();
  const [isCandleLit, setIsCandleLit] = useState(false);
  const [step, setStep] = useState(0);

  const handleLightCandle = () => {
    setIsCandleLit(true);
    setStep(1);
  };

  const handleNextStep = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      onNext();
    }
  };

  return (
    <div
  className="loading-screen"
  style={{
    backgroundImage: `url(${Nabi8})`, // Gunakan template literal untuk membangun URL
    backgroundSize: "cover",         // Pastikan gambar memenuhi area
    backgroundPosition: "center",    // Pusatkan gambar
    backgroundRepeat: "no-repeat",   // Hindari pengulangan gambar
  }}
>

      <div className="text">
        <h1>Happy Birthday!</h1>
        <div className="cake">
          <div className={`candle ${isCandleLit ? "lit" : ""}`} />
          <div className="cake-body"></div>
        </div>
        <div className="animated-typing">
          {step === 0 && "Selamat ulang tahun sayangku! Nyalakan lilin dan make a wish."}
          {step === 1 && "Semoga apa yang diharapkan bisa tercapai ❤️"}
        </div>
      </div>
      <button onClick={handleLightCandle} className={`button ${step > 0 ? "hidden" : ""}`}>
        Nyalakan Lilin
      </button>
      <button onClick={() => navigate("/screen1")} className={`button ${step > 0 ? "" : "hidden"}`}>
        Selanjutnya
      </button>
    </div>
  );
};

export default LoadingScreen;
