/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./box2.css"; // Gaya CSS untuk styling

const Box2 = () => {
  const startDate = new Date("2023-09-05T00:00:00"); // Ganti dengan tanggal dan waktu mulai hubungan Anda
  const [timeElapsed, setTimeElapsed] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  // Fungsi untuk menghitung selisih waktu
  const calculateTimeElapsed = () => {
    const now = new Date();
    const difference = now - startDate; // Selisih waktu dalam milidetik

    const years = Math.floor(difference / (1000 * 3600 * 24 * 365)); // Tahun
    const months = Math.floor((difference % (1000 * 3600 * 24 * 365)) / (1000 * 3600 * 24 * 30)); // Bulan
    const days = Math.floor((difference % (1000 * 3600 * 24 * 30)) / (1000 * 3600 * 24)); // Hari
    const hours = Math.floor((difference % (1000 * 3600 * 24)) / (1000 * 3600)); // Jam
    const minutes = Math.floor((difference % (1000 * 3600)) / (1000 * 60)); // Menit
    const seconds = Math.floor((difference % (1000 * 60)) / 1000); // Detik

    setTimeElapsed({ years, months, days, hours, minutes, seconds });
  };

  // Menggunakan useEffect untuk menghitung ulang setiap detik
  useEffect(() => {
    calculateTimeElapsed();
    const intervalId = setInterval(calculateTimeElapsed, 1000); // Mengupdate setiap detik
    return () => clearInterval(intervalId); // Membersihkan interval saat komponen unmount
  }, []);

  return (
    <div className="box2-container">
      <div className="box2-content">
        <h2>Our Relationship Duration</h2>
        <p>
          {`Years: ${timeElapsed.years} | Months: ${timeElapsed.months} | Days: ${timeElapsed.days} | `}
          {`Hours: ${timeElapsed.hours} | Minutes: ${timeElapsed.minutes} | Seconds: ${timeElapsed.seconds}`}
        </p>
      </div>
    </div>
  );
};

export default Box2;
