import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import Screen2 from "./Screen2";
import MusicPlayerScreen from "./Screen3";
import ScreenComponent from "./screen4/ScreenComponent";
import Screen1 from "./screen1";
import DotsAnimation from "./Love";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoadingScreen />} />
        <Route path="/screen1" element={<Screen1/>} />
        <Route path="/screen2" element={<Screen2 />} />
        <Route path="/screen3" element={<MusicPlayerScreen />} />
        <Route path="/screen4" element={<ScreenComponent />} />
        <Route path="/screen5" element={<DotsAnimation />} />
        </Routes>
    </Router>
  );
};

export default App;
