import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TypingBubble from "../TypingBubble";
import WhatsAppContainer from "../WhatsAppContainer";
import { motion } from "framer-motion";

const Screen1 = () => {
  const navigate = useNavigate();
  const [showNext, setShowNext] = useState(false);

  const handleAnimationComplete = () => {
    setShowNext(true);
  };

  return (
    <WhatsAppContainer>
      <TypingBubble
        text="Selamat ulang tahun! 🎉 semoga sayang bisa menjadi lebih baik dan selalu mengandalkan Tuhan dalam segala hal."
        onComplete={handleAnimationComplete}
      />
      {showNext && (
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => navigate("/screen2")}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#25D366",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Next
        </motion.button>
      )}
    </WhatsAppContainer>
  );
};

export default Screen1;
