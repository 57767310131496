import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TypingBubble from "../TypingBubble";
import WhatsAppContainer from "../WhatsAppContainer";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";

const Screen2 = () => {
  const navigate = useNavigate();
  const [showNext, setShowNext] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const handleAnimationComplete = () => {
    setShowNext(true);
  };

  const handleAllow = () => {
    setShowPermissionModal(false);
    // Arahkan ke screen3 setelah izin diberikan
    navigate("/screen3", { state: { autoplayAllowed: true } });
  };

  const handleDeny = () => {
    setShowPermissionModal(false);
    // Arahkan ke screen3 jika izin ditolak
    navigate("/screen3", { state: { autoplayAllowed: false } });
  };

  const handleNextClick = () => {
    setShowPermissionModal(true);
  };

  return (
    <WhatsAppContainer >
      <TypingBubble
        text="maaf ulang tahun yang kedua kalinya nda bisa ba sama-sama, hehe lupakan jo yang tu pertama. semoga di umur sekarang ada banyak berkat yang ditambahkan, iloveyouuu sayangku cantik ❤️"
        onComplete={handleAnimationComplete}
      />
      {showNext && (
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleNextClick}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#25D366",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Next
        </motion.button>
      )}

      {/* Modal for Permission */}
      {showPermissionModal && (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Permission Required</h5>
              </div>
              <div className="modal-body">
                <p>
                  The next screen includes a music player that requires
                  permission to autoplay. Do you allow autoplay?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleAllow}
                >
                  Allow
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeny}
                >
                  Deny
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </WhatsAppContainer>
  );
};

export default Screen2;
