/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { FooterSpotify } from "../assets";
import songFile from '../assets/music.mp3';
import {
  Foto1, Foto2, Foto3, Foto4, Foto5, Foto6, Foto7, Foto8, Foto9, Foto10,
  Foto11, Foto12, Foto13, Foto14, Foto15, Foto16, Foto17, Foto18, Foto19, Foto20,
  Foto21, Foto22, Foto23, Foto24, Foto25, Foto26, Foto27, Foto28, Foto29, Foto30,
  Foto31, Foto32, Foto33, Foto34, Foto35, Foto36, Foto37, Foto38, Foto39, Foto40,
  Foto41, Foto42, Foto43, Foto44, Foto45, Foto46, Foto47, Foto48, Foto49, Foto50,
  Foto51, Foto52, Foto53, Foto54, Foto55, Foto56, Foto57, Foto58, Foto59
} from "../assets/index.js";

const MusicPlayerScreen = () => {
  const location = useLocation();
  const autoplayAllowed = location.state?.autoplayAllowed || false;
  const [isPlaying, setIsPlaying] = useState(false);
  const audio = React.useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    audio.current = new Audio(songFile);
    audio.current.loop = true;

    if (autoplayAllowed) {
      const playAudio = async () => {
        try {
          await audio.current.play();
          setIsPlaying(true);
        } catch (err) {
          console.log("Autoplay failed", err);
        }
      };
      playAudio();
    }

    return () => {
      if (audio.current) {
        audio.current.pause();
        setIsPlaying(false);
      }
    };
  }, [autoplayAllowed]);

  // Toggle play/pause functionality
  const togglePlayPause = () => {
    if (isPlaying) {
      audio.current.pause();
    } else {
      audio.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Array of image URLs
  const imageUrls = [
    Foto1, Foto2, Foto3, Foto4, Foto5, Foto6, Foto7, Foto8, Foto9, Foto10,
    Foto11, Foto12, Foto13, Foto14, Foto15, Foto16, Foto17, Foto18, Foto19, Foto20,
    Foto21, Foto22, Foto23, Foto24, Foto25, Foto26, Foto27, Foto28, Foto29, Foto30,
    Foto31, Foto32, Foto33, Foto34, Foto35, Foto36, Foto37, Foto38, Foto39, Foto40,
    Foto41, Foto42, Foto43, Foto44, Foto45, Foto46, Foto47, Foto48, Foto49, Foto50,
    Foto51, Foto52, Foto53, Foto54, Foto55, Foto56, Foto57, Foto58, Foto59,
  ];

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "430px",
        height: "100vh",
        margin: "0 auto",
        backgroundImage: "linear-gradient(to bottom, #015D4C, #000000)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #ccc",
        position: "relative", // Add this to allow positioning of the "Next" button
      }}
    >
      {/* Next Button in the top right corner */}
      <button
        onClick={() => navigate("/screen4")}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          background: "none",
          border: "none",
          color: "#fff",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        Next
      </button>

      <div
        style={{
          width: "100%",
          maxWidth: "350px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {/* Scrolling Images Animation */}
        {isPlaying && (
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              width: "100%",
              height: "350px", // Control the height of the image area
              marginTop: "20px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                animation: "scrollImages 120s linear infinite", // Slow speed for smooth animation
              }}
            >
              {/* Map through the imageUrls array to render images dynamically */}
              {imageUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Image ${index + 1}`}
                  style={{
                    width: "350px",
                    height: "350px",
                    objectFit: "cover",
                  }}
                />
              ))}
            </div>
          </div>
        )}

        {/* Song Info */}
        <div
          style={{
            textAlign: "center",
          }}
        >
          <h2 style={{ fontSize: "20px", margin: "0 0 5px 0" }}>Song Title</h2>
          <p style={{ fontSize: "16px", color: "#b3b3b3", margin: 0 }}>Artist Name</p>
        </div>

        {/* Progress Bar */}
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "4px",
              backgroundColor: "#535353",
              borderRadius: "2px",
              position: "relative",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                width: "40%",
                height: "100%",
                backgroundColor: "#1DB954",
                borderRadius: "2px",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              color: "#b3b3b3",
            }}
          >
            <span>1:15</span>
            <span>3:45</span>
          </div>
        </div>

        {/* Controls */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <button
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: "24px",
              cursor: "pointer",
            }}
          >
            <img
              src="https://img.icons8.com/ios-filled/30/ffffff/shuffle.png"
              alt="Shuffle"
            />
          </button>
          <button
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: "24px",
              cursor: "pointer",
            }}
          >
            <img
              src="https://img.icons8.com/ios-filled/30/ffffff/rewind.png"
              alt="Previous"
            />
          </button>
          <button
            style={{
              background: "#1DB954",
              border: "none",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={togglePlayPause}
          >
            <img
              src={`https://img.icons8.com/ios-filled/30/ffffff/${isPlaying ? "pause" : "play"}.png`}
              alt={isPlaying ? "Pause" : "Play"}
            />
          </button>
          <button
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: "24px",
              cursor: "pointer",
            }}
          >
            <img
              src="https://img.icons8.com/ios-filled/30/ffffff/fast-forward.png"
              alt="Next"
            />
          </button>
          <button
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: "24px",
              cursor: "pointer",
            }}
          >
            <img
              src="https://img.icons8.com/ios-filled/30/ffffff/repeat.png"
              alt="Repeat"
            />
          </button>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 70,
          width: "100%",
        }}
      >
        <img src={FooterSpotify} alt="Footer Spotify" style={{ width: "100%" }} />
      </div>

      {/* CSS for image scrolling */}
      <style>
        {`
          @keyframes scrollImages {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
      </style>
    </div>
  );
};

export default MusicPlayerScreen;
