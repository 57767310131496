import React from "react";
import "./box4.css"; // Pastikan styling sesuai kebutuhan
import linkVideo from "../../../assets/videos/lv_0_20241028104051.mp4";

const Box4 = () => {
  // Path yang benar

  return (
    <div className="box4-container">
      <div className="box4-content">
        <h2>I LOVE YOU</h2>
        <div className="video-container">
          <video
            controls
            width="100%" // Ukuran video sesuai container
            height="100%"
            autoPlay // Menyebabkan video diputar otomatis
            preload="auto" // Memastikan video dimuat sebelumnya
          >
            <source src={linkVideo} type="video/mp4" />
            <p>Your browser does not support the video tag.</p>{" "}
            {/* Pesan fallback jika browser tidak mendukung */}
          </video>
        </div>
      </div>
    </div>
  );
};

export default Box4;
