import React, { useState } from "react";
import "./FlexBoxSection.css";

// Import box content
import Box1 from "./boxes/box1";
import Box2 from "./boxes/box2";
import Box3 from "./boxes/box3";
import Box4 from "./boxes/box4";
import { BirthdayGif, Nabi11 } from "../../assets";

const FlexBoxSection = () => {
  const [popupContent, setPopupContent] = useState(null);

  const handleBoxClick = (boxId) => {
    switch (boxId) {
      case 1:
        setPopupContent(<Box1 />);
        break;
      case 2:
        setPopupContent(<Box2 />);
        break;
      case 3:
        setPopupContent(<Box3 />);
        break;
      case 4:
        setPopupContent(<Box4 />);
        break;
      default:
        setPopupContent(null);
    }
  };

  const closePopup = () => {
    setPopupContent(null);
  };

  return (
    <div
      className="flexbox-section"
      style={{
        backgroundImage: `url(${Nabi11})`, // Gunakan template literal untuk membangun URL
        backgroundSize: "cover", // Pastikan gambar memenuhi area
        backgroundPosition: "center", // Pusatkan gambar
        backgroundRepeat: "no-repeat", // Hindari pengulangan gambar
      }}
    >
      <h1
        style={{
          display: "flex",
          justifyContent: "center", // Mengatur konten di tengah secara horizontal
          alignItems: "center",
        }}
      >
        <strong>Our library</strong>
      </h1>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center", // Mengatur konten di tengah secara horizontal
          alignItems: "center", // Mengatur konten di tengah secara vertikal
        }}
      >
        <img
          style={{
            maxWidth: "100%", // Membatasi lebar gambar agar responsif
            height: "auto",
            marginBottom: "10rem",
            marginTop: "30px", // Menjaga proporsi gambar
          }}
          src={BirthdayGif}
          alt="Deskripsi GIF"
        />
      </div>

      <main className="content">
        {["I", "Love", "You", "🩷"].map((name, index) => (
          <div
            key={index}
            className="box"
            onClick={() => handleBoxClick(index + 1)} // Tetap gunakan index untuk identifikasi box
          >
            <strong>{name}</strong>
          </div>
        ))}
      </main>

      {/* Popup */}
      {popupContent && (
        <div className="popup">
          <div className="popup-content">
            {popupContent}
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlexBoxSection;
