import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const TypingBubble = ({ text, onComplete }) => {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + text[index]);
        setIndex(index + 1);
      }, 50); // Waktu delay antar karakter
      return () => clearTimeout(timeout);
    } else if (onComplete) {
      onComplete(); // Panggil callback saat teks selesai dianimasikan
    }
  }, [index, text, onComplete]);

  return (
    <motion.div
      className="bubble"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        padding: "10px 15px",
        backgroundColor: "#dcf8c6",
        borderRadius: "20px",
        maxWidth: "70%",
        margin: "10px auto",
      }}
    >
      {displayText}
    </motion.div>
  );
};

export default TypingBubble;
